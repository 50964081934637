<template>
  <b-modal
    :id="id"
    title="¿Descartar?"
    centered
    modal-class="avify-confirmation-discard-modal"
    :hide-header-close="true"
    size="xs"
  >
    ¿Seguro que quieres descartar los cambios?
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="xl"
          class="float-right"
          @click="$emit('onCancelDiscardModal')"
        >
          No, gracias
        </b-button>
        <b-button
          variant="secondary"
          size="xl"
          class="float-left"
          @click="$emit('onConfirmDiscardModal')"
        >
          Descartar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DiscardChangesModal',
  props: {
    id: {
      type: String
    }
  }
}
</script>

<style lang="less">
  @import "../../style/text";
  .avify-confirmation-discard-modal {

    .modal-xs {
      @media (min-width: 576px) {
        max-width: 400px;
      }
    }

    .modal-content {
      background-color: #31395A;
      border-bottom: none;
    }

    .modal-header {
      border-bottom: none;
      justify-content: center;
    }

    .modal-title {
      color: white;
      font-size: 14px;
      @media (min-width: 576px) {
        font-size: 20px;
      }
    }

    .modal-body {
      text-align: center;
      color: white;
    }

    .modal-footer {
      border-top: none;
    }

  }
</style>
