<template>
  <HardwareLayout v-if="true">
    <b-card
      :title="'Agregar Hardware'"
      class="user-create-card"
      align="left"
    >
      <HardwareForm
        ref="hardwareForm"
        :form_prop.sync="form"
      />
      <b-row class="footer">
        <b-col>
          <b-button variant="secondary" @click="onDiscard" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onSave()">
            Agregar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <DiscardChangesModal
      :id="confirmationModalId"
      @onConfirmDiscardModal="onConfirmDiscard"
      @onCancelDiscardModal="onCancelDiscard"
    />
  </HardwareLayout>
</template>

<script>
import HardwareLayout from '../layout/HardwareLayout.vue'
import HardwareForm from '@/components/Business/Hardware/HardwareForm/HardwareForm.vue'
import { BIconTrash } from 'bootstrap-vue'
import { createHardwareApi } from '@/api/business/AvifyHardwareApi'
import DiscardChangesModal from '@/components/Modal/DiscardChangesModal'

export default {
  name: 'HardwareCreate',
  components: {
    HardwareLayout,
    HardwareForm,
    BIconTrash,
    DiscardChangesModal
  },
  data () {
    return {
      form: this.getEmpty()
    }
  },
  computed: {
    confirmationModalId () {
      return 'confirmation-discard-modal' + 'id'
    }
  },
  methods: {
    async onSave () {
      const isFormValid = await this.$refs.hardwareForm.$refs.hardwareForm.validate()
      if (!isFormValid) {
        return
      }
      this.lockScreen()
      const hardwareToCreate = { ...this.form }
      hardwareToCreate.brandId = this.form.brand.id
      hardwareToCreate.productTypeId = this.form.productType.id
      delete hardwareToCreate.brand
      delete hardwareToCreate.productType
      createHardwareApi(hardwareToCreate)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'hardware-list' })
        })
    },
    onDiscard () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onCancelDiscard () {
      this.$bvModal.hide(this.confirmationModalId)
    },
    onConfirmDiscard () {
      this.onCancelDiscard()
      this.$router.push({ name: 'hardware-list' })
    },
    getEmpty () {
      return {
        name: '',
        brand: {
          id: null,
          name: ''
        },
        productType: {
          id: null,
          name: ''
        },
        description: null,
        visible: false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "hardwareCreate";
</style>
